import styleConfig from '@/styles/style-config'
const GridLines = (props) => {
  return (
    <>
      <div className="grid-lines absolute inset-0 container-lines__inner">
        <div></div>
        <div></div>
        <div></div>
        <div className='no-border-right'></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <style jsx>{
        `
          .container-lines__inner {
            display: grid;
            justify-content: center;
            grid-template-columns: 98px repeat(2, 1fr) 98px;
            gap: 10px;
            grid-template-rows: 1fr;
          }
          .container-lines__inner div {
            border-left: solid 0.5px ${'#' + (props.gridLinesColor || '000000') + 'bf'};
            border-right: solid 0.5px ${'#' + (props.gridLinesColor || '000000') + 'bf'};
            opacity: 0.2;
          }
          .container-lines__inner div:first-child {
            border-left: none;
          }
          .no-border-right {
            border-right: none !important;
          }
          .container-lines__inner div:last-child {
            border-right: none;
          }
          @media(min-width:${styleConfig.theme.screens.md}) {
            .container-lines__inner {
              justify-content: flex-start;
              grid-template-columns: 75px 171.5px 1fr 172.5px 75px;
            }
            .no-border-right {
              border-right: solid 0.5px ${'#' + (props.gridLinesColor || '000000') + 'bf'} !important;
            }
          }
          @media(min-width:${styleConfig.theme.screens.lg}) {
            .container-lines__inner {
              justify-content: center;
              grid-template-columns: 98px repeat(5, 1fr) 98px;
            }
            .no-border-right {
              border-right: solid 0.5px ${'#' + (props.gridLinesColor || '000000') + 'bf'} !important;
            }
          }
          @media(min-width:${styleConfig.theme.screens.xl}) {
            .container-lines__inner {
              grid-template-columns: 177px repeat(5, 1fr) 177px;
            }
            .no-border-right {
              border-right: solid 0.5px ${'#' + (props.gridLinesColor || '000000') + 'bf'} !important;
            }
          }
        `
      }
      </style>
    </>
  )
}
export default GridLines